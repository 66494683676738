/* eslint-disable no-plusplus */
import React from 'react';
import './documents.scss';
import { IProductLineDocument } from '../../../../consts/interfaces-for-request';
import { ApiStorage } from '../../../../consts/api';
import docImage from '../../../../images/doc.png';

interface IDocumentsProps {
  documents: IProductLineDocument[];
}

export default ({ documents }: IDocumentsProps) => {
  const lineDocuments = documents;

  return (
    <div className="full-width">
      <div className="row documents-row">
        {lineDocuments.map((row, i) => (
          <a
            target="_blank"
            key={`document_link_${row.id}_${i}`}
            href={`${ApiStorage}/${row.filePath}`}
            rel="noreferrer"
          >
            <div
              key={`document_${row.fileId}`}
              className="document-item col-md-6"
            >
              <span className="image">
                <img className="document-item__image" src={docImage} />
              </span>
              <span className="text">{row.description}</span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};
