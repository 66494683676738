let ApiUrl;
let ApiStorage;

switch (process.env.ENVIRONMENT) {
  case "production": {
    ApiUrl = 'https://api.tdrezer.ru/api';
    ApiStorage = 'https://api.tdrezer.ru/api/storage';
    break;
  }
  case "staging": {
    ApiUrl = 'https://api.rzr.dev.hedgesoft.net/api';
    ApiStorage = 'https://api.rzr.dev.hedgesoft.net/api/storage';
    break;
  }
  default: {
    ApiUrl = 'http://localhost:4242/api';
    ApiStorage = 'http://localhost:4242/api/storage';
  }
}

export {
  ApiUrl,
  ApiStorage
}
