import * as React from 'react';
import { ProvideMediaMatchers, MediaMatcher } from 'react-media-match';
import './phone-number-mobile.scss';

export default () => (
  <ProvideMediaMatchers>
    <MediaMatcher
      mobile={<a className="phone-number mobile" href="tel:+79659112211">+7 965 911 22 11</a>}
      desktop={<span className="phone-number desktop">+7 965 911 22 11</span>}
    />
  </ProvideMediaMatchers>
);
