/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-trailing-spaces */
import React from "react";
import { Link } from "react-router-dom";
import "./about-us.scss";
import DetailsBlock from "../../shared/details-block/details-block";

export default () => (
  <article className="about-us">
    <h1>О компании</h1>
    <section>
      <p>
        Наша история началась в 2013 году, когда в сварочном цеху нам
        потребовался автоматический котел. В то время нечто подобное стоило
        дорого, и мы спроектировали котёл сами. Оборудование получилось
        качественным и недорогим, об этом узнали люди и начали к нам обращаться.
      </p>
      <p>
        Сейчас наши котлы устанавливают в частных домах, автомобильных
        мастерских, на производствах, складах и любых других помещениях, которые
        нуждаются в дешевом и безотказном тепле. Основные поставки приходятся на
        территории Красноярского края. Подробнее об оплате и доставке можно
        узнать на странице <Link to="/delivery"> Оплата и доставка</Link>.
      </p>
      <p>
        Наше производство обладает парком современного оборудования и САПР,
        позволяющим решать практически любую задачу от этапа проектирования до
        готового изделия.
        <ul className="blue-bullets-list">
          В парк нашего оборудования входят:
          <li>ЧПУ токарные обрабатывающие комплексы,</li>
          <li>ЧПУ фрезерные обрабатывающие комплексы,</li>
          <li>Лазерные раскройные комплексы,</li>
          <li>ЧПУ листогибочные прессы,</li>
          <li>Термопласт автоматы,</li>
          <li>Контактная сварка/MIG/MAG/MMA/TIG/Лазерная сварка</li>
          <li>Широкий спектр механического оборудования.</li>
        </ul>
      </p>
    </section>
    <section>
      <h2>Почему с нами выгодно?</h2>
      На это есть несколько причин:
      <ul className="first-word-blue">
        <li>
          <b>Цена.</b> Мы — производители, поэтому мы предлагаем самую низкую
          цену на рынке котельного оборудования.
        </li>
        <li>
          <b>Короткие сроки поставки.</b> Вы обращаетесь напрямую к нам и мы
          отгружаем котёл в кратчайшие сроки.
        </li>
        <li>
          <b>Максимальное базовое оснащение.</b> Мы поставляем продукцию в
          полной рабочей комплектации. После установки котла вам понадобится
          только топливо для запуска работы. Базовая рабочая фракция угля —
          «орех», которая широко доступна на всей территории России.
        </li>
        <DetailsBlock buttonLabel="Показать, что входит в базовую комплектацию">
          <ul className="blue-bullets-list">
            Базовое оснащение котлов включает:
            <li>Система реверсшнек;</li>
            <li>Выравнивание давления в топке и топливном бункере;</li>
            <li>Выведен клапан для пассивной системы пожаротушения;</li>
            <li>Увеличенный зольный ящик;</li>
            <li>Цельносварной бункер 550л из стали 1.5-2мм;</li>
            <li>Система «Стоп-уголь»;</li>
            <li>
              Возможность установки подачи и дверей на левую и на правую
              сторону;
            </li>
            <li>Вывод трубы дымохода в различных направлениях;</li>
            <li>Зольный ящик;</li>
            <li>Совок для удаления золы;</li>
            <li>Расширенная приемная горловина бункера;</li>
          </ul>
        </DetailsBlock>
      </ul>
      <h3>Гарантийный срок на продукцию</h3>
      <ul className="first-word-blue">
        <li>
          <b>1 год</b>на электрическую часть (моторедуктор/ вентилятор/
          компьютер*),
        </li>
        <li>
          <b>2 года</b> на механическую часть (горелка + шнековая подача
          топлива*).
        </li>
        <li>
          <b>3 года</b> на гидравлическую часть (Герметичность топки котла**).
        </li>
        <li>
          <b>*</b> Гарантия предоставляет при пуско-наладочных работах нашим
          мастером. Пуско-наладка оплачивается отдельно.
        </li>
        <li>
          <b>**</b>Гарантия предоставляется при соблюдении норм сантехнического
          монтажа котла, при котором исключается проверка внутреннего давления
          без предохранительного клапана давлением 3 бар.
        </li>
        В городе Красноярске и пригороде работает сервисная бригада. Для
        покупателей из других регионов помощь и консультации оказывают наши
        региональные представители или главный офис в режиме телефонной связи.
      </ul>
    </section>

    <section>
      <h2>Как мы обеспечиваем надежность оборудования</h2>
      <p>
        Наши котлы имеют классическую конструкцию с горизонтальными
        теплообменниками. Такой тип компоновки топки позволяет легко очищать
        котёл от золы парой простых движений, без лишних усилий (в сравнении с
        котлами с вертикальными теплообменниками).
      </p>
      <p>
        Благодаря использованию толстой стали марки 09Г2С, котлы имеют срок
        службы до 20 лет без возможности сквозного прогорания топки. Сварной
        цельнометаллический каркас позволяет выдерживать внутреннее рабочее
        давление до 3 атм, что отвечает требованиям к эксплуатации большинства
        систем отопления.
      </p>
      <p>
        Топочные узлы в котлах нашего производства обладают повышенной
        пропускной способность, благодаря запатентованной геометрии канала
        шнека. Базовая фракция угля для наших топочных узлов составляет от 25 до
        50мм и по общей классификации называется “орех”. Такой тип фракции на
        порядок дешевле фракции “семечка” (5-25мм), сгорает в более полном
        объеме и широко доступен на всей территории России.
      </p>
      <p>
        Перед отправкой покупателю котлы проходят обкатку механических частей,
        регулировку подвижных соединений и первичную настройку контроллера, для
        того, чтобы котёл можно было запустить без каких либо проблем.
      </p>
      <DetailsBlock buttonLabel="Показать дополнительные характеристики производства">
        <ul className="blue-bullets-list">
          При производстве наших котлов мы:
          <li>Используем сталь 09Г2С толщиной 6мм,</li>
          <li>Опрессовываем топку воздухом и водой под давлением в 3.5 атм,</li>
          <li>Наносим жаростойкую краску,</li>
          <li>Обкатываем шнековые транспортеры в течение 45 минут,</li>
          <li>
            Используем подачи с повышенной пропускной способностью. (Стандартная
            фракция угля «орех» до 50мм),
          </li>
          <li>
            Все детали и элементы котла имеют 100% геометрическую повторяемость.
          </li>
        </ul>
      </DetailsBlock>
    </section>
  </article>
);
