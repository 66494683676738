/* eslint-disable camelcase */
import React from 'react';
import './knowthedifference.scss';
import imgdiff1 from '../../images/knowthedifference/difference_1.png';
import imgdiff2 from '../../images/knowthedifference/difference_2.png';
import imgdiff3 from '../../images/knowthedifference/difference_3.png';
import imgdiff4 from '../../images/knowthedifference/difference_4.png';
import imgdiff5 from '../../images/knowthedifference/difference_5.png';
import imgdiff6_1 from '../../images/knowthedifference/difference_6_1.png';
import imgdiff6_2 from '../../images/knowthedifference/difference_6_2.png';
import imgdiff7_1 from '../../images/knowthedifference/difference_7_1.png';
import imgdiff7_2 from '../../images/knowthedifference/difference_7_2.png';
import imgdiff8 from '../../images/knowthedifference/difference_8.png';
import imgdiff9 from '../../images/knowthedifference/difference_9.png';
import ModalPicture from '../shared/modal-picture/modal-picture';

export default () => {
  const [modalPicture, openModalPicture] = React.useState(false);
  const [viewedImage, changeImage] = React.useState('');
  const [viewedImageAlt, changeAlt] = React.useState('');
  const [viewedImageDescription, changeDescription] = React.useState('');

  const openPicture = (imageSrc, alt, description) => {
    changeImage(imageSrc);
    changeAlt(alt);
    changeDescription(description);
    openModalPicture(true);
  };

  return (
    <article>
      <section className="know-the-difference desktop container-small">
        <h1>Проверьте, прежде чем купить!</h1>
        <p>
          Знаете ли вы, что котлы Rezer обладают уникальными особенностями дизайна и
          конструкции, которые выделяют их на фоне продукции конкурентов?
          <br />
          В этой статье мы, на примере продуктов конкурентов, похожих на наши, подробно расскажем
          о тех тонкостях и инновациях, которые позволяют легко отличиить нашу продукцию от похожей.
        </p>
        <div>
          <div>
            <button
              type="button"
              onClick={() => openPicture(imgdiff1,
                '1. Мы не используем красный цвет',
                `Одной из особенностей дизайна наших котлов является синий цвет котла с черной крышкой
          - это стиль и традиция Rezer. Минимализм в дизайне и строгость в исполнении. Наш котёл
          никогда не будет вызывающе красным, как на этом изображении`)}
            >
              <img src={imgdiff1} alt="1. Мы не используем красный цвет" />
            </button>
          </div>
          <div>
            <h2>1. Мы не используем красный цвет</h2>
            <p>
              Одной из особенностей дизайна наших котлов является синий цвет котла с черной крышкой
              - это стиль и традиция Rezer. Минимализм в дизайне и строгость в исполнении. Наш котёл
              никогда не будет вызывающе красным, как на этом изображении
            </p>
            <hr />
          </div>
        </div>

        <div>
          <div>
            <button
              type="button"
              onClick={() => openPicture(imgdiff2,
                '2. Мы не используем польские компьютеры',
                `Вместо использования польских компьютеров, мы выбираем решения Российского производителя ДомАвтоматика.
             Это обеспечивает максимальную надежность, доступность и эффективность работы наших котлов.`)}
            >
              <img src={imgdiff2} alt="Мы не используем польские компьютеры" />
            </button>
          </div>
          <div>
            <h2>2. Мы не используем польские компьютеры</h2>
            <p>
              Вместо использования польских компьютеров, мы выбираем решения Российского
              производителя ДомАвтоматика. Это обеспечивает максимальную надежность,
              доступность и бесперебойные поставки.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <div>
            <button
              type="button"
              onClick={() => openPicture(imgdiff3,
                '3. Только штампованные витки шнека.',
                ` Мы используем штампованные витки спиралей толщиной 6мм для наших шнеков,
          в отличие от цельнотянутой спирали с толщиной витка у основания 4мм,
          а на краю 2.5-3мм, используемых конкурентами.
          Это делает наши шнеки более прочными,
          долговечными и устойчивыми к абразивному износу.`)}
            >
              <img src={imgdiff3} alt="Мы не используем цельнотянутую тонкую спираль шнека" />
            </button>
          </div>
          <div>
            <h2>3. Мы не используем цельнотянутую тонкую спираль шнека</h2>
            <p>
              Мы используем штампованные витки спиралей толщиной 6мм для наших шнеков,
              в отличие от цельнотянутой спирали с толщиной витка у основания 4мм,
              а на краю 2.5-3мм, используемых конкурентами.
              Это делает наши шнеки более прочными,
              долговечными и устойчивыми к абразивному износу.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <div>
            <button
              type="button"
              onClick={() => openPicture(imgdiff4,
                '4. Наши угольные подачи снабжены дополнительной системой стоп-уголь',
                `Все наши угольные подачи оснащены системой стоп-уголь, что позволяет остановить
        свободное падения угля при техническом обслуживании канала шнека.
        Обратите внимание на форму шнека,,
        что обеспечивает повышенную пропускную способность и, как следствие,
        стабильное горение угля.`)}
            >
              <img src={imgdiff4} alt="Наши угольные подачи снабжены дополнительной системой стоп-уголь" />
            </button>
          </div>
          <div>
            <h2>4. Наши угольные подачи снабжены дополнительной системой стоп-уголь</h2>
            <p>
              Все наши угольные подачи оснащены системой стоп-уголь, что позволяет остановить
              свободное падения угля, при техническом обслуживании канала шнека.
              Сам канал шнека имеет отличную от других производителей форму,
              что обеспечивает повышенную пропускную способность и, как следствие,
              стабильное горение угля.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <div>
            <button
              type="button"
              onClick={() => openPicture(imgdiff5,
                '5. Наши угольные подачи не дымят',
                `Наши угольные подачи не дымят, даже при малом количестве топлива,
        благодаря уникальной системе анти-дым.
        Это делает работу наших котлов более экологичной и безопасной для вашего дома.`)}
            >
              <img src={imgdiff5} alt="Наши угольные подачи не дымят" />
            </button>
          </div>
          <div>
            <h2>5. Наши угольные подачи снабжены дополнительной системой анти-дым</h2>
            <p>
              Наконец, наши угольные подачи не дымят, даже при малом количестве топлива,
              благодаря уникальной системе анти-дым.
              Это делает работу наших котлов более экологичной и безопасной для вашего дома.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <div className="horizontal-buttons">
            <button
              type="button"
              onClick={() => openPicture(imgdiff6_1, '6.1 Только мощные двигатели (0.55 кВт)',
                ` Наши котлы Rezer оснащены мощными двигателями на 0.55кВт, обеспечивающими
          стабильную и бесперебойную подачу угля к зоне горения.`)}
            >
              <img src={imgdiff6_1} alt="Только мощные двигатели" />
            </button>
            <button
              type="button"
              onClick={() => openPicture(imgdiff6_2,
                '6.2 Реверс-шнек вместо срезного шлинта',
                `Мы отказались от использования
            механической предохранительной системы со срезным шлинтом, которую неудобно и долго
            обслуживать при возникновении аварийной ситуации и заменили её на интеллектуальную
            систему реверс-шнек`)}
            >
              <img src={imgdiff6_2} alt="Реверс-шнек вместо срезного шлинта" />
            </button>
          </div>
          <div>
            <h2>6. Мы постоянно совершенствуем и улучшаем механизм подачи топлива.</h2>
            <p>
              Наши котлы Rezer оснащены мощными двигателями на 0.55кВт, обеспечивающими
              стабильную и бесперебойную подачу угля к зоне горения. Мы отказались от использования
              механической предохранительной системы со срезным шлинтом, которую неудобно и долго
              обслуживать при возникновении аварийной ситуации и заменили её на интеллектуальную
              систему реверс-шнек, сделав процесс работы более простым и эффективным.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <div className="horizontal-buttons">
            <button
              type="button"
              onClick={() => openPicture(imgdiff7_1,
                '7.1 Сварные (а не клёпанные) бункера',
                'Бункера наших угольных подач сварные, а не клёпанные, как у большинства конкурентов.Это увеличивает их прочность и устойчивость к деформациям.')}
            >
              <img src={imgdiff7_1} alt="Бункера сварные, а не клёпанные" />
            </button>
            <button
              type="button"
              onClick={() => openPicture(imgdiff7_2,
                '7.2 Система стоп-уголь',
                `Благодаря
              уникальной системе стоп-уголь, подача угля в канал шнека может быть остановлена
              в любой момент, что обеспечивает удобство в обслуживании.`)}
            >
              <img src={imgdiff7_2} alt="Мы используем систему стоп-уголь" />
            </button>
          </div>
          <div>
            <h2>7. Сварные (а не клёпанные) бункера снабжённые системой стоп-уголь</h2>
            <p>
              Бункера наших угольных подач сварные, а не клёпанные, как у большинства конкурентов.
              Это увеличивает их прочность и устойчивость к деформациям. Кроме того, благодаря
              уникальной системе стоп-уголь, подача угля в канал шнека может быть остановлена
              в любой момент, что обеспечивает удобство в обслуживании.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <div>
            <button
              type="button"
              onClick={() => openPicture(imgdiff8,
                '8. Шнек патентованного профиля (повышенная пропускная способность)',
                `Уникальная функция реверс-шнека предотвращает ситуацию заклинивания шнека, отвечает за
          сохранность моторедуктора и обеспечивает бесперебойную работу наших котлов. Вместо
          обычных труб, используемых в качестве канала шнека, мы используем только наш
          патентованный профиль повышенной пропускной способности, позволяющий, в некоторых
          ситуациях, проталкивать кусочки угля до размеров 50мм/65мм/75мм.`)}
            >
              <img src={imgdiff8} alt="Шнек патентованного профиля повышенной пропускной способности" />
            </button>
          </div>
          <div>
            <h2>8. Шнек патентованного профиля (повышенная пропускная способность)</h2>
            <p>
              Уникальная функция реверс-шнека предотвращает ситуацию заклинивания шнека, отвечает за
              сохранность моторедуктора и обеспечивает бесперебойную работу наших котлов. Вместо
              обычных труб, используемых в качестве канала шнека, мы используем только наш
              патентованный профиль повышенной пропускной способности, позволяющий, в некоторых
              ситуациях, проталкивать кусочки угля до размеров 50мм/65мм/75мм.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <div>
            <button
              type="button"
              onClick={() => openPicture(imgdiff9,
                '9. Равномерное и продолжительное горение.',
                `Канал шнека горелки изготовлен из чугуна с толстой стенкой, а не из стали, что делает его
            более устойчивым к высоким температурам и абразивному воздействию угля. Вместо горелок
            лоткового типа, наши горелки оборудованы чугунной плитой патентованной формы, что
            гарантирует равномерное и продолжительное горение.`)}
            >
              <img src={imgdiff9} alt="Канал шнека горелки выполнен из толстостенного чугуна" />
            </button>
          </div>
          <div>
            <h2>9. Равномерное и продолжительное горение за счёт патентованной плиты.</h2>
            <p>
              Канал шнека горелки изготовлен из чугуна с толстой стенкой, а не из стали, что делает его
              более устойчивым к высоким температурам и абразивному воздействию угля. Вместо горелок
              лоткового типа, наши горелки оборудованы чугунной плитой патентованной формы, что
              гарантирует равномерное и продолжительное горение.
            </p>
            <hr />
          </div>
        </div>
        <div>
          <p>
            Выбирая котлы Rezer, вы получаете надежность, эффективность и инновации, которые делают
            наши продукты уникальными. Мы заботимся о ваших потребностях в отоплении и стараемся
            сделать наши котлы еще лучше.
          </p>
        </div>
        <div>
          <h2>Добро пожаловать в семью Rezer!</h2>
        </div>
      </section>
      <section>
      
        <ModalPicture
          modalState={modalPicture}
          closeModal={() => openModalPicture(false)}
          overlay
          description={viewedImageAlt}
        >
          <div className="big-modal-image">
            <img src={viewedImage} alt={viewedImageAlt} />
            <p>{viewedImageDescription}</p>
          </div>
        </ModalPicture>
      </section>
    </article>
  );
};
