/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import './call-me-form.scss';
import './call-me-form-mobile.scss';
import { ApiUrl } from '../../../consts/api';
import ButtonSubmit from '../buttons/button-submit';
import * as buttonTypes from '../../../consts/button-types';
import PhoneInput from "react-phone-number-input/input";
import {useState} from "react";

interface CallMeFormProps {
  isMobile: boolean;
}

export default ({ isMobile }: CallMeFormProps) => {
  const formAttribute = isMobile ? { tabIndex: -1, role: 'dialog', className: 'call-me-form mobile' } : {};
  const attributeParent = isMobile ? {} : { tabIndex: -1, role: 'dialog' };
  const [isSent, toSend] = React.useState(false);
  const [value, setValue] = useState();

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = handleSubmit(({ tel }) => {
    toSend(true);
    window.callMeFormSent();

    axios.get(`${ApiUrl}/message/send?isCallback=true&phone=${tel}`)
      .then((response) => console.log(response))
      .catch((error) => console.log(error.message));
  });

  return (
    <div>
      {!isSent ? (
        <div className={isMobile ? 'call-me-form mobile' : 'call-me-form desktop'} {...attributeParent}>
          <form name="callMeForm" onSubmit={onSubmit} {...formAttribute}>
            <h2>
              Вам позвонить?
            </h2>
            <span>Оставьте свой номер и мы сами вам позвоним</span>
            <div>
              <label htmlFor="tel">Телефон:</label>
              <PhoneInput onChange={setValue} type="tel" id="tel" name="tel" ref={register({ required: true })} placeholder="+7 (999) 000 00 00" />
              <ButtonSubmit label="Позвоните мне!" className={buttonTypes.longCallmeButton} />
              {errors.tel && <p className="error">Введите номер телефона</p>}
            </div>
          </form>
        </div>
      ) : (
        <div className={isMobile ? 'call-me-form-response mobile' : 'call-me-form-response desktop'} {...attributeParent}>
          <h3>Ваша заявка принята!</h3>
          <h4>Скоро наш менеджер свяжется с вами</h4>
        </div>
      )}
    </div>
  );
};
