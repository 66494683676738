import React from 'react';
import './product-pictures-mobile.scss';
import { IImage, IVideo } from '../../../../../consts/interfaces-for-request';
import { ApiStorage } from '../../../../../consts/api';
import { YouTubePathParts } from '../../../../../consts/youtube-path-parts';

interface ProductPictureProps {
  imageInfoList: IImage[];
  videoInfoList: IVideo[];
}

export default ({ imageInfoList, videoInfoList }: ProductPictureProps) => {
  const [selectedElement, setSelectedElement] = React.useState(0);

  const shortImageList: (IImage | IVideo)[] = imageInfoList != null
    ? imageInfoList.slice(0, 2) : [];

  if (videoInfoList != null && videoInfoList[0] != null) {
    shortImageList.push(videoInfoList[0]);
  } else if (imageInfoList.length > 2) {
    shortImageList.push(imageInfoList[2]);
  }
  const thumbImageList: any[] = [];

  if (shortImageList?.length) {
    shortImageList.forEach((item) => {
      if ((item as IImage).fullPath !== undefined) {
        thumbImageList.push(
          <div key={`short_image_holder_${item.id}`} className="small-picture">
            <img
              src={`${ApiStorage}/${(item as IImage).fullPath}`}
              alt={item.description}
            />
          </div>,
        );
      } else if ((item as IVideo).url !== undefined) {
        const videoId = (item as IVideo).url.split('=')[1];
        thumbImageList.push(
          <div
            key={`short_image_holder_video_${item.id}`}
            className="small-picture">
            <iframe
              src={`${YouTubePathParts.FirstPartVideoLink}/${videoId}`}
              title={(item as IVideo).description}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>,
        );
      }
    });
  }

  const setNextElement = () => {
    if (selectedElement + 1 < thumbImageList.length) {
      setSelectedElement(selectedElement + 1);
    } else {
      setSelectedElement(0);
    }
  };
  
  const setPrevElement = () => {
    if (selectedElement - 1 > 0) {
      setSelectedElement(selectedElement + 1);
    } else {
      setSelectedElement(0);
    }
  };

  return (
    <div className="product-pictures mobile">
      { thumbImageList[selectedElement]}
      {thumbImageList.length > 1 && (
        <div className="control-row">
          <button type="button" onClick={() => setPrevElement()}>&lt;&lt;</button>
          <button type="button" onClick={() => setNextElement()}>&gt;&gt;</button>
        </div>
      )}
    </div>
  );
};
