/* eslint-disable no-plusplus */
import React, { useEffect } from "react";
import "./feature-table.scss";
import {
  IFeatureTable,
  IProduct,
} from "../../../../consts/interfaces-for-request";

interface IFeatureTableProps {
  featureTable: IFeatureTable;
  currentProduct: IProduct;
}

export default ({ featureTable, currentProduct }: IFeatureTableProps) => {
  const ftTbale = featureTable;
  const curProduct = currentProduct;
  let curNumber = ftTbale.products.findIndex((x) => x.id === curProduct.id);
  if (curNumber < 0 || curNumber >= ftTbale.products.length) {
    curNumber = 0;
  }
  useEffect(() => {
    const item = document.getElementById("feature-table-dynamic-id");

    window.addEventListener("wheel", (e) => {
      if (item !== null) {
        if (e.deltaY > 0) item.scrollLeft += 100;
        else item.scrollLeft -= 100;
      }
    });
  });
  return (
    <div className="feature">
      <div className="feature-const">
        <table id="feature-table-dynamic-id" className="feature-table-const">
          <thead>
            <tr>
              <th key="feature_type_table_header" className="feature_title">
                Характеристика
              </th>
              <th
                key="feature_type_unit_table_header"
                className="feature_title"
              >
                Ед Изм.
              </th>
              <th
                key="feature_type_table_header_current"
                className="feature-current-item"
              >
                {curProduct.name}
              </th>
            </tr>
          </thead>
          <tbody>
            {ftTbale.featuresByType.map((row, i) => (
              <tr key={`feature_type_table_${row.featureTypeId}`}>
                <td
                  key={`feature_type_row_${row.featureTypeId}_${i}`}
                  className="feature_title"
                >
                  {row.featureTypeName}
                </td>
                <td
                  key={`feature_type_row_unit_${row.featureTypeId}_${i}`}
                  className="feature_title"
                >
                  {row.featureTypeMeasurement}
                </td>
                <td
                  key={`feature_type_cell_current_${row.features[curNumber].id}_${row.featureTypeId}_${i}`}
                  className="feature-current-item"
                >
                  {row.features[curNumber].value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="plug" />
      </div>
      {ftTbale.products.length > 1 && (
        <div className="feature-dynamic">
          <table className="feature-table-dynamic">
            <thead>
              <tr>
                {ftTbale.products.map((product, i) => {
                  if (i !== curNumber) {
                    return (
                      <th
                        className="feature-dynamic-item"
                        key={`feature_type_table_header_${product.id}_${product.id}_${i}`}
                      >
                        {product.name}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {ftTbale.featuresByType.map((row, i) => (
                <tr key={`feature_type_dynamic_table_${row.featureTypeId}`}>
                  {row.features.map((cell, j) => {
                    if (j !== curNumber) {
                      return (
                        <td
                          className="feature-dynamic-item"
                          key={`feature_type_cell_${cell.id}_${row.featureTypeId}_${i}_${j}`}
                        >
                          {cell.value}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
