import React from 'react';

import './product-pictures.scss';
import { IImage, IVideo } from '../../../../../consts/interfaces-for-request';
import ModalPicture from '../../../../shared/modal-picture/modal-picture';
import { ApiStorage } from '../../../../../consts/api';
import { YouTubePathParts } from '../../../../../consts/youtube-path-parts';

interface ProductPictureProps {
  imageInfoList: IImage[];
  videoInfoList: IVideo[];
}

export default ({ imageInfoList, videoInfoList }: ProductPictureProps) => {
  const [modalPicture, openModalPicture] = React.useState(false);
  const [modalVideo, openModalVideo] = React.useState(false);

  const [viewedImage, changeImage] = React.useState('');
  const [viewedImageAlt, changeAlt] = React.useState('');

  const [viewedVideo, changeVideo] = React.useState('');
  const [viewedVideoDescription, changeVideoDescription] = React.useState('');

  const openPicture = (a: IImage) => {
    changeImage(a.fullPath);
    changeAlt(a.description);
    openModalPicture(true);
  };

  const openVideo = (a: IVideo) => {
    const videoId = a.url.split('=')[1];
    changeVideo(`${YouTubePathParts.FirstPartVideoLink}/${videoId}`);
    changeVideoDescription(a.description);
    openModalVideo(true);
  };

  const shortImageList: (IImage | IVideo)[] = imageInfoList != null
    ? imageInfoList.slice(0, 2)
    : [];

  if (videoInfoList != null && videoInfoList[0] != null) {
    shortImageList.push(videoInfoList[0]);
  } else if (imageInfoList.length > 2) {
    shortImageList.push(imageInfoList[2]);
  }

  const thumbImageList: any[] = [];
  if (shortImageList?.length) {
    shortImageList.forEach((item) => {
      if ((item as IImage).fullPath !== undefined) {
        thumbImageList.push(
          <div key={`short_image_holder_${item.id}`} className="small-picture">
            <button type="button" onClick={() => openPicture(item as IImage)}>
              <img
                src={`${ApiStorage}/${(item as IImage).thumbPath}`}
                alt={item.description}
              />
            </button>
          </div>,
        );
      } else if ((item as IVideo).url !== undefined) {
        thumbImageList.push(
          <div key={`short_image_holder_${item.id}`} className="small-picture">
            <button type="button" onClick={() => openVideo(item as IVideo)}>
              <div className="youtube-button-holder">
                <img
                  src={YouTubePathParts.YouTubeButtonLink}
                  alt="Кнопка YouTube"
                  className="inner"
                />
              </div>
              <img
                src={`${YouTubePathParts.FirstPartPreviewLink}/${
                  (item as IVideo).url.split('=')[1]
                }/${YouTubePathParts.LastPartPreviewLink}`}
                alt={(item as IVideo).description}
              />
            </button>
          </div>,
        );
      }
    });
  }

  return (
    <div className="product-pictures desktop">
      {thumbImageList ?? ''}

      <div className="medium-picture">
        {imageInfoList.length && (
          <button type="button" onClick={() => openPicture(imageInfoList[0])}>
            <img
              src={`${ApiStorage}/${imageInfoList[0].thumbPath}`}
              alt={imageInfoList[0].description}
            />
          </button>
        )}
      </div>

      <ModalPicture
        modalState={modalPicture}
        closeModal={() => openModalPicture(false)}
        overlay
        description={viewedImageAlt}
      >
        <img
          className="full-image"
          src={`${ApiStorage}/${viewedImage}`}
          alt={viewedImageAlt}
        />
      </ModalPicture>

      <ModalPicture
        modalState={modalVideo}
        closeModal={() => openModalVideo(false)}
        overlay
        description={viewedVideoDescription}
      >
        <iframe
          src={viewedVideo}
          title={viewedVideoDescription}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </ModalPicture>
    </div>
  );
};
