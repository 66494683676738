/* eslint-disable max-len */
import * as React from 'react';
import './pay-and-delivery-mobile.scss';

export default () => (
  <section className="pay-and-delivery mobile">
    <h1>Оплата и доставка</h1>
    <p>
      Оплатить продукцию можно переводом:
    </p>
    <ul className="blue-bullets-list">
      <li>
        на банковскую карту,
      </li>
      <li>
        на расчетный счет без НДС,
      </li>
    </ul>
    <h2>Доставка</h2>
    <p>
      Доставка заказов осуществляется одним из следующих способов:
    </p>
    <ul className="blue-bullets-list">
      <li>
        Грузовой машиной с манипулятором (в пределах г. Красноярска и пригорода),
      </li>
      <li>
        Отправка транспортной компанией (Деловые линии, Байкал-Сервис),
      </li>
      <li>
        Самовывоз со склада по адресу г. Красноярск, пгт. Берёзовка ул. Щорса 8\14,
      </li>
    </ul>
    <p>
      Доставка транспортной компанией и грузовой машиной с манипулятором оплачивается покупателем при получении.
    </p>
  </section>
);
