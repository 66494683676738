import * as React from 'react';
import './landing-info.scss';
import { Link } from 'react-router-dom';

interface LandingInfoProps {
	mobile: boolean,
}

export default ({ mobile }: LandingInfoProps) => {
	const className = mobile ? 'mobile' : 'container-small desktop';
	return (
		<div className={`landing-info ${className}`}>
			<div className="why-us col-md-6">
				<h2><Link to="/about#root">Наше производство</Link></h2>
				<ul>
					<li>С 2013 года активно развиваем производство котлов.</li>
					<li>
						Двойной контроль качества топки, жаростойкость, повышенная пропускная способность канала шнека – ключевые аспекты наших котлов.
					</li>
					<li>Предоставляем гарантию на электрические и механические компоненты.</li>
					<li>Лучшая цена, быстрые сроки поставки и высокое качество продукции.</li>
				</ul>
				<div className="align-center">
					<Link to="/about#root">Узнать больше</Link>
				</div>
			</div>
			<div className="why-us  col-md-6">
				<h2><Link to="/know-the-difference">Проверьте прежде чем купить</Link></h2>
				<ul>
					<li>Сделайте осознанный выбор при покупке котлов отопления.</li>
					<li>Отличайте гарантированное качество и надежность Rezer от подражателей.</li>
					<li>Узнайте, как наши технологии и особенности дизайна выделяют нас на рынке.</li>
					<li>Уникальные конструкции и системы, обеспечивающие комфорт и тепло вашего дома.</li>
				</ul>
				<div className="align-center">
					<Link to="/know-the-difference">Узнать больше</Link>
				</div>
			</div>
		</div>
	);
};
