import React from 'react';
import '../showcase-advantages-mobile.scss';

export default () => (
  <ul className="advantages boiler">
    <li>
      Высокая эффективность.
      <br />
      (КПД 85-90%)
    </li>
    <li>
      Автономная работа
      <br />
      котла
    </li>
    <li>
      Легкость
      <br />
      обслуживания
    </li>
  </ul>
);
